import useGa from '@analytics/hooks/useGa';
import Button from '@ui/components/Button';
import Logo from '@ui/components/Logo';
import MainImage from '@ui/components/MainImage';
import Title from '@ui/components/Title';

const LandingPage = () => {
  const logToGa = useGa();

  const handleClick = () =>
    logToGa({
      event: 'button_click',
      button: {
        text: 'Naplánovat akci',
        type: 'start page',
        url: 'https://pujdu.centrum.cz/e',
        phase: 'start',
      },
    });

  return (
    <>
      <div className="relative flex w-full flex-wrap px-6 lg:max-w-[480px]">
        <div className="flex w-full items-center justify-center lg:justify-start">
          <Logo />
        </div>
        <div className="relative mt-[34px] flex w-full items-center justify-center md:mt-[56px] lg:mt-[75px] lg:justify-start">
          <Title size="big">
            Najděte termín,
            <br />
            který se hodí všem.
            <br />
            Na pár kliků.
          </Title>
        </div>
        <div className="mt-[34px] flex w-full items-center justify-center md:mt-[56px] lg:justify-start">
          <Button size="big" link="/e" onClick={handleClick}>
            Naplánovat akci
          </Button>
        </div>
      </div>
      <MainImage type="homepage" />
    </>
  );
};

export default LandingPage;
