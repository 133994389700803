import { ReactNode } from 'react';

import clsx from 'clsx';

export interface LayoutFullPageWrapperProps {
  children?: ReactNode;
  className?: string;
}

const LayoutFullPageWrapper = ({
  children,
  className,
}: LayoutFullPageWrapperProps) => (
  <div
    className={clsx(
      'relative mx-auto flex max-w-[1080px] flex-wrap items-start justify-center pt-[42px] before:absolute before:left-1/2 before:top-[-686px] before:h-[982px] before:w-full before:-translate-x-1/2 before:rounded-full before:bg-brand-white-100 before:blur-[118px] after:absolute after:bottom-0 after:left-1/2 after:h-[206px] after:w-full after:-translate-x-1/2 after:rounded-full after:bg-brand-green-100 after:opacity-[.06] after:blur-[118px] lg:justify-start lg:pt-[138px] lg:after:bottom-[-206px]',
      className,
    )}
  >
    {children}
  </div>
);

export default LayoutFullPageWrapper;
