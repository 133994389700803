import clsx from 'clsx';

import {
  isTypeError404OrError500,
  isTypeHomepage,
} from '@ui/helpers/MainImageHelpers';
import { PageType } from '@ui/types';
import { getAssetUrl } from '@utils/getAssetUrl';

interface MainImageProps {
  type: PageType;
}

const MainImage = ({ type }: MainImageProps) => (
  <div
    className={clsx(
      'mt-[56px] flex w-full items-center justify-center lg:absolute lg:right-0 lg:top-[0] lg:mt-[108px] lg:w-auto',
      isTypeHomepage(type) && 'max-h-[808px] max-w-[698px]',
      isTypeError404OrError500(type) && 'max-h-[698px] max-w-[698px]',
    )}
  >
    <picture>
      <source type="image/webp" srcSet={getAssetUrl(`/images/${type}.webp`)} />
      <source type="image/jpeg" srcSet={getAssetUrl(`/images/${type}.png`)} />
      <img
        src={getAssetUrl(`/images/${type}.png`)}
        alt="Centrum Půjdu"
        title="Centrum Půjdu"
      />
    </picture>
  </div>
);

export default MainImage;
