import { ReactNode } from 'react';

import clsx from 'clsx';

export interface TitleProps {
  children?: ReactNode;
  className?: string;
  size?: 'regular' | 'big';
}

const Title = ({ children, className, size = 'regular' }: TitleProps) => (
  <h1
    className={clsx(
      'bg-title bg-clip-text font-dosis text-transparent',
      size === 'regular' && 'text-[48px]/[58px] font-normal',
      size === 'big' &&
        'text-[24px]/[32px] font-extrabold sm:text-[36px]/[44px] md:text-[46px]/[58px] lg:text-[54px]/[72px]',
      className,
    )}
  >
    {children}
  </h1>
);

export default Title;
