import { useEffect } from 'react';

import { NextPage } from 'next';

import useReloadHeaderBidding from '@adverts/hooks/useReloadHeaderBidding';
import useSasicAttributes from '@adverts/hooks/useSasicAttributes';
import useGaPage from '@analytics/hooks/useGaPage';
import Footer from '@footer/components/Footer';
import LandingPage from '@landingPage/components/LandingPage';
import Meta from '@seo/components/Meta';
import LayoutFullPageWrapper from '@ui/components/LayoutFullPageWrapper';

const HomePage: NextPage = () => {
  const logGaPage = useGaPage();

  useEffect(() => {
    logGaPage('homepage');
  }, [logGaPage]);

  useReloadHeaderBidding();
  useSasicAttributes({
    keyword: ['homepage'],
  });

  return (
    <>
      <Meta
        title="Půjdu.cz - Hlasování o termínu setkání snadno a rychle!"
        description="Na Půjdu.cz hlasujete o termínech a zjistíte ideální čas pro posezení u piva, sport nebo oslavu. Užijte si společné chvíle s přáteli bez zbytečného stresu."
      />
      <LayoutFullPageWrapper>
        <LandingPage />
        <Footer className="lg:mt-60" />
      </LayoutFullPageWrapper>
    </>
  );
};
export default HomePage;
